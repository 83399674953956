import React from "react"
import { Layout } from '../layout'
import { Helmet } from 'react-helmet'
export default function Menu() {
  return <Layout>
    <Helmet>
      <title>Dinner Menu – Eric's Italian Bistro – Modern & Italian Cuisine</title>
    </Helmet>
    <div className="menu">
      <h1>New Menu coming soon!</h1>
      <br /><br />
      <p>A 3.5% admin fee is added to credit card transactions</p>
    </div>
  </Layout>
}
